import Service from '../Service'

export default class extends Service {
  constructor (id) {
    const url = ['stock-control', 'purchase-orders']
    if (id) {
      url.push(id)
    }
    super(url)
  }

  submit (data) {
    return this.post(data, '/submit')
  }

  updateSubmit (data) {
    return this.put(data, '/submit')
  }

  getLastPO () {
    return this.get(null, '/last-po')
  }

  approve () {
    return this.post(null, '/approve')
  }

  reject () {
    return this.post(null, '/reject')
  }

  cancel () {
    return this.post(null, '/cancel')
  }

  void (data) {
    return this.post(data, '/void')
  }

  payment (data) {
    return this.post(data, '/payment')
  }
}
