<template>
  <gf-content overflow>
    <div>
      <gf-table :data="histories" :scrollable="false" v-loading="loading">
        <el-table-column prop="date" label="Date" width="180">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column prop="time" label="Time" width="180">
          <template slot-scope="slot">
            {{ $GetTime(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column prop="subject" label="Subject">
        </el-table-column>
        <el-table-column prop="description" label="Description">
          <template slot-scope="slot">
            <div v-for="(value, name) in slot.row.description" :key="name">
              <span class="font-weight-bold">{{ name }}: </span>
              <span>{{ value }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="user" label="User">
          <template slot-scope="slot">
            {{ slot.row.user.fname }} {{ slot.row.user.lname }}
          </template>
        </el-table-column>
      </gf-table>
    </div>
  </gf-content>
</template>

<script>
import PurchaseOrderService from '@/services/v1/PurchaseOrder'
import PurchaseOrderHistoryService from '@/services/v1/PurchaseOrderHistory'

export default {
  data() {
    return {
      purchaseOrderId: this.$route.params.id,
      poNumber: null,
      histories: [],
      loading: false
    }
  },
  methods: {
    async getPurchaseOrder() {
      try {
        const poService = new PurchaseOrderService(this.purchaseOrderId)
        const result = await poService.get()
        this.poNumber = result.data.po_number
      } catch (error) {
        this.$Error(error)
      }
    },
    async getPOHistories() {
      try {
        this.loading = true
        const phService = new PurchaseOrderHistoryService(this.purchaseOrderId)
        const result = await phService.list()
        this.histories = result.data.rows
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    this.$emit('update:active', 2)

    await this.getPurchaseOrder()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Stock control' },
      { title: 'Purchase Order' },
      { title: this.poNumber },
      { title: 'History' }
    ])

    this.getPOHistories()
  }
}
</script>
